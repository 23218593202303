<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;">
    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: calc(100% - 80px);">
      <div slot="header" class="flex" style="align-items: center">
        <span>学员数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '学员状态'">
              <el-tag  :style=" 'background' +':' + getColor(scope.row.current_state)">
                <span style="color:#FFF">{{ scope.row.current_state == '在读' ? '在读' : scope.row.current_state == '试听' ? '试听' : scope.row.current_state == '待跟进' ? '待跟进' : scope.row.current_state == '退学' ? '退学': scope.row.current_state == '休学' ? '休学' :'毕业'}}</span>
              </el-tag>
            </template>
            <template v-else-if="item.label === '创建时间'">
              <span v-if="scope.row.createTime"> {{ scope.row.createTime.substring(0, 19) }}</span>
              <span v-else>--</span>
            </template>

            <template v-else-if="item.label === '账号状态'">
              <span
                class="span-state"
                :style=" 'backgroundColor' +':' +getStateColor(scope.row.status)"
              ></span>
              <span  :style=" 'color' +':' + getStateColor(scope.row.status)">{{ scope.row.status }}</span>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import getStateColor from "@/mixins/getStateColor";
import getColor from "@/mixins/getColor";
import {
  getStudentList,
  forbiddenMerchant
} from "@/api/member/merchant";
import DetailModal from "@/views/member/DetailModal";
import TextButton from "@/components/TextButton";
// 默认展示列

const baseColSetting = [
  {
    label: "手机号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "性别",
    prop: "sex",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "学员状态",
    prop: "current_state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "账号状态",
    prop: "status",
    // prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getStateColor,getColor],
  components: {DetailModal, TextButton},
  data() {
    return {
      merchantInfo:{},
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      statusOption:{
        '在读': "success",
        '试听':'',
        '待跟进': "info",
        '退学': 'danger',
        '休学':"info",
        '毕业':'info'
      },
      statusOptions: {
        1: "success",
        3: "danger",
      },
      options: [
        {
          value: "正常",
          label: "正常",
        },
        {
          value: "禁用",
          label: "禁用",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 获取学员列表
     */

    async getListHandler() {
      let responseData = await getStudentList(
        {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          needCount: this.pageInfo.needCount,
          merchantId: this.$route.query.id
        }
      );

      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.tableData.forEach(item => {
          if(item.state === '1') {
            item.status = '已启用'
          }else if(item.state === '3') {
            item.status = '已禁用'
          }
        })
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const {id, state} = item;
      forbiddenMerchant({
        id,
        merchantStatus: state == "正常" ? "禁用" : '正常',
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    openDetail(row) {
      // 打开商户详情
      this.$router.push({
        path: '/merchantAccount/merchantAccountDetail',
        query: {
          classID: row.class_id,
          courseId: row.courseId,
        },
      })
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

