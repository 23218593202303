export default {
  methods:{
    getColor(state) {
      switch (state) {
        case '在读':
          return '#67C23A'
        case '试听':
          return '#33CCFF'
        case '退学':
          return '#F56C6C'
        case '待跟进':
          return '#909399'
        case '休学':
          return '#909399'
        case '毕业':
          return '#909399'
        default:
          return '#C8C6C6'
      }
      
    },
  }
}