<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    :title="dialogTitle"
    @open="openDialog"
    top="5vh"
  >
    <el-form
      :model="formData"
      :rules="realRules"
      :inline="false"
      ref="formData"
      label-width="120px"
      class="demo-form-inline"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formData.name"  placeholder="请填写账号姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="loginName" >
        <el-input v-model="formData.loginName" placeholder="请填写手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwd" >
        <el-input type="password" v-model="formData.pwd" placeholder="请填写密码（密码必须包含大写字母、小写字母、数字、特殊字符中的三种）"></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="identityIdList">
        <el-select
          clearable
          multiple
          @change="handleChange"
          v-model="formData.identityIdList"
          placeholder="请选择用户类型"
          class="w100"
        >
          <el-option
            v-for="item in roleoption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio v-model="formData.state" :label="1">启用</el-radio>
        <el-radio v-model="formData.state" :label="3">禁用</el-radio>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="confirm()">确定</el-button>
        <el-button @click="$emit('close')">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getIdentityList,
} from "@/api/system/userType";
import { passwordCheck} from '@/api/user'
export default {
  props: {
    merchantInfo: {
      default: null,
      type: Object,
    },
    show: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields();
        }
      }
    },
  },
  data() {
    const validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogTitle: "",
      formData: {
        identityIdList: [],
      },
      id:'',
      roleoptions: [

      ],
      roleoption:[],
      realRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        loginName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        identityIdList: [
          {
            type: "array",
            required: true,
            message: "请选择员工角色",
            trigger: ["blur",'change']
          },
        ],
        pwd: [{ required: true, message: "请输入初始密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)", trigger: "blur" }],
        state: [{ required: true, message: "请输入状态", trigger: "blur" }],
      },
      rulesEdit: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        loginName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        identityIdList: [
          {
            type: "array",
            required: true,
            message: "请选择员工角色",
            // trigger: "change",
            trigger: ["blur",'change']
          },
        ],
        state: [{ required: true, message: "请输入状态", trigger: "blur" }],
      },
      clock:true
    };
  },
  methods: {
    handleChange() {
      this.$forceUpdate();
    },
    openDialog() {
      this.getListHandler();
      if (this.merchantInfo === null) {
        this.dialogTitle = "创建账号";
        this.formData = {
          name: "",
        }
      } else {
        this.dialogTitle = "编辑账号";
        this.realRules = this.rulesEdit;
        this.formData = Object.assign({}, this.merchantInfo);
        this.$set(this.formData, 'identityIdList', [])
        this.formData.identityIdList = this.formData.identities.map(item => item.id);
      }
    },

    close() {
      this.$emit("close");
    },

    confirm() {
      this.$refs.formData.validate(async(valid) => {
        if (valid) {
          
          if (this.merchantInfo === null) {
            // 密码校验
            const formdate = new FormData();
            formdate.append('password', this.formData.pwd,)
            const res = await passwordCheck(formdate)
            if(!res.body){
              window.$msg('当前新密码不符合密码规范，请重新填写密码（密码必须包含大写字母、小写字母、数字、特殊字符中的三种）', 2)
              return
            }
            this.$emit("submit", this.formData);
          } else {
            // 密码校验
            if(this.formData.pwd){
              const formdate = new FormData();
              formdate.append('password', this.formData.pwd,)
              const res = await passwordCheck(formdate)
              if(!res.body){
                window.$msg('当前新密码不符合密码规范，请重新填写密码（密码必须包含大写字母、小写字母、数字、特殊字符中的三种）', 2)
                return
              }
            }
            this.$emit("editsubmit", this.formData);
          }
          this.close();
        }
      });
    },
    /**
     * 获取列表
     */

    async getListHandler() {
      let responseData = await getIdentityList({
        group: "教务管理平台",
      });
      if (responseData && responseData.state == "success") {
        this.roleoptions = responseData.body.list;
        this.roleoptions.forEach((v)=>{
            v.value = v.id
            v.label = v.name
        })
        this.roleoption = this.roleoptions
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
