<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-row>
      <el-col :span="8">
        <el-card
          shadow="never"
          style="overflow-y: scroll; height: calc(100% - 80px)"
        >
          <div slot="header" class="flex" style="align-items: center">
            <span>教师列表</span>
            <div class="flex_1"></div>
            <el-checkbox v-model="checked" @change="checkChange"
              >为商户分配课程</el-checkbox
            >
            <text-button
              icon="el-icon-refresh"
              contentTit="刷新"
              @click.native="search"
            ></text-button>
            <text-button
              icon="el-icon-setting"
              contentTit="列设置"
              :colSetting="colSetting"
              @update="update"
              :ispopover="true"
              :baseColSetting="baseColSetting"
            ></text-button>
          </div>

          <el-table
            :default-sort="{ prop: 'open_date', order: 'descending' }"
            :key="tableKey"
            @select="dialogCheck"
            ref="table"
            fit
            :highlight-current-row="true"
            :data="tableData"
          >

            <el-table-column
              v-for="item of defaultHeader"
              :key="item.label"
              :label="item.label"
              :align="item.align ? item.align : 'left'"
              :min-width="item.width ? item.width : 'auto'"
              :sortable="item.sortable ? item.sortable : false"
              :prop="item.prop"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="10" style="margin-left: 30px">
        <div class="row-bg" style="padding: 20px" v-if="checked">
          <el-tabs v-model="activeName">
            <el-tab-pane label="编程课程" name="编程">
              <el-tree
                :data="data"
                show-checkbox
                style="margin-top: 20px"
                ref="eltreeDatas"
                node-key="id"
                :default-expand-all="true"
                :default-checked-keys="checkedArray"
                :props="defaultProps"
              >
              </el-tree>
            </el-tab-pane>
          </el-tabs>

          <div style="text-align: left; margin-top: 30px">
            <el-button type="primary" @click="confirm" size="mini"
              >保存修改</el-button
            >
          </div>
        </div>
        <div class="row-bg" style="padding: 20px" v-if="show">
          <el-tabs v-model="activeName">
            <el-tab-pane label="编程课程" name="编程">
              <el-tree
                :data="dataTree"
                show-checkbox
                style="margin-top: 20px"
                ref="elData"
                node-key="id"
                :default-expand-all="true"
                :default-checked-keys="checkedArrays"
                :props="defaultPropsData"
              >
              </el-tree>
            </el-tab-pane>
            <el-tab-pane label="培训课程" name="培训">
              <el-tree
                :data="datapx"
                style="margin-top: 20px"
                show-checkbox
                ref="eltreeData"
                node-key="id"
                :default-expand-all="true"
                :default-checked-keys="checkedArrayTrain"
                :props="defaultProps"
              >
              </el-tree>
            </el-tab-pane>
          </el-tabs>
          <div style="text-align: left; margin-top: 30px">
            <el-button type="primary" @click="confirm1" size="mini"
              >保存修改</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  getTreeCourseTwo,
  distributeCourse,
  getEmployeeList,
  getTreeCourseThird,
  listCoursePreparation,
  addCoursePreparationUser,
  listMerchantCourse,
  getUserCourseListByUser,
  batchCourseUserByUser
} from "@/api/member/merchant";
import DetailModal from "@/views/member/DetailModal";
import TextButton from "@/components/TextButton";
import getAccountColor from "@/mixins/getStateColor";
import costInfoVue from "../../../views/statistics/cost-info.vue";

// 默认展示列

const baseColSetting = [
  {
    label: "登录账号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getAccountColor],
  components: { DetailModal, TextButton },
  data() {
    return {
      checked: false,
      show:false,
      activeName: "编程",
      size: "small",
      statusOptions: {
        1: "success",
        3: "danger",
      },
      data: [],
      datapx:[],
      dataTree: [],
      tableKey: 1,
      checkedArray: [],
      checkedArrayTrain:[],
      checkedArrays: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      defaultPropsData: {
        children: "children",
        label: "name",
      },
      tableData: [],
      // pageInfo: {
      //   pageNum: 1,
      //   pageSize: 10,
      //   total: 0,
      //   needCount: true,
      // },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      selectioned: {}, //单选中的值
      userId: "",
      sectionList:{
        userId :'',
        courseUnitIds:[]
      },
      courseList:[]
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  created() {
    this.getListHandler();
  },
  methods: {
    async saveCourseTrain() {
      let thembArray = this.$refs.eltreeData.getCheckedNodes();
      let courseIdArray = []
      thembArray.forEach((item) => {
        if (item.courseGroup === undefined || item.courseGroup === null) {
          courseIdArray.push(item.id)
        }
      });

      let responseData = await batchCourseUserByUser({
          courseIds: courseIdArray,
          userIds:[this.userId],
          isSystemAccount:false,
        }
      );

      if (responseData && responseData.state == "success") {
        this.getUserCourseListByUser()
      }
    },
    async getUserCourseListByUser() {
      const res = await getUserCourseListByUser({
        userId: this.userId,
      })
      this.checkedArrayTrain = [];
      this.$refs.eltreeData.setCheckedNodes( []);
      res.body.forEach(item => {
        this.checkedArrayTrain.push(item.courseId)
      })

    },
    //商户选择框
    checkChange() {
      this.checked = true
      this.show = false
      if (this.checked) {
        this.listDistributedCourse('编程');
        this.listMerchantCourse()
        this.activeName = '编程'
      }
      this.$refs.table.clearSelection();
      this.selectioned = {};
    },
    //员工选择框
    dialogCheck(selection, row) {
      this.checked = false
      this.$refs.table.clearSelection()
      this.show = true
      if (row) {
        this.selectioned = row;
        this.$refs.table.toggleRowSelection(row, true);
        this.userId = this.selectioned.id;
      }
      this.getUserCourseListByUser()
      this.listCoursePreparation();
    },
    search() {
      this.show = false
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    async listMerchantCourse() {
      let responseData = await listMerchantCourse({
        merchantId: this.$route.query.id,
      });
      if (responseData && responseData.state == "success") {
        this.checkedArray = []
        responseData.body.forEach((item)=> {
          this.checkedArray.push(item.courseId)
        })
        this.$forceUpdate()
      }
    },
    // 表格
    async getListHandler() {
      let responseData = await getEmployeeList({
        pageNum: 1,
        pageSize: 10000,
        needCount: true,
        merchantId: this.$route.query.id,
      });

      if (responseData && responseData.state == "success") {
        this.tableData = []
        responseData.body.list.forEach((item)=> {
          item.identities.forEach((value)=> {
            if(value.name === '教师') {
              item.teachFlag = true
            }
          })
          if(item.state === 1 && item.teachFlag) {
            this.tableData.push(item)
          }
        })
      }
      this.checked = true
      this.listDistributedCourse('培训'); //课程列表
      this.listDistributedCourse('编程'); //课程列表
      this.listMerchantCourse()
      this.listGetTreeCourseThird(); //三级树结构
    },
    /**
     * 页码改变
     */
    // handleCurrentChange(page) {
    //   this.pageInfo.pageNum = page;
    //   this.getListHandler();
    // },
    /**
     * pageSize改变
     */
    // handleSizeChange(size) {
    //   this.pageInfo.pageSize = size;
    //   this.getListHandler();
    // },
    // 获取商户已被授权的课程
    async listDistributedCourse(type) {
      let responseData = await getTreeCourseTwo({
        courseGroup: type,
      });
      if (responseData && responseData.state == "success") {
        if(type === '编程') {
          this.data = responseData.body;
          this.data.forEach((item) => {
            item.title = item.courseGoodsName;
          });
        }else if(type === '培训') {
          this.datapx = responseData.body;
          this.datapx.forEach((item) => {
            item.title = item.courseGoodsName;
          });
        }
      }
    },

    // 三级课程结构
    async listGetTreeCourseThird() {
      let responseData = await getTreeCourseThird({});
      if (responseData && responseData.state == "success") {
        this.dataTree = responseData.body;
      }
    },
    // 获取用户分配得备授课列表
    async listCoursePreparation() {
      let responseData = await listCoursePreparation({
        userId: this.userId,
      });
      if (responseData && responseData.state == "success") {
        this.checkedArrays = [];
        this.$refs.elData.setCheckedNodes( []);
        responseData.body.forEach((item) => {
          if(item.prepare == 'Y'){
            this.checkedArrays.push(item.courseUnitId);
          }
        });
      }
    },
    // 课节保存修改
    async confirm1() {
      this.saveCourseTrain()
      let thembArray = this.$refs.elData.getCheckedNodes();
      this.sectionList = {
          userId :'',
          courseUnitIds:[]
      }
      if (thembArray.length === 0) {
        let object = {
          userId: this.userId,
          courseUnitIds:[]
        };
        this.sectionList = object;
      } else {
        thembArray.forEach((item) => {
          if (item.children == undefined || item.children == null) {
            // 课节的id
            this.sectionList.courseUnitIds.push(item.id);
            this.sectionList.userId = this.userId;
          }
        });
      }
      if (this.sectionList.length === 0) {
        let object = {
          userId: this.userId,
          courseUnitIds:[]
        };
        this.sectionList = object ;
      }
      let responseData = await addCoursePreparationUser(
        JSON.stringify(this.sectionList)
      );

      if (responseData && responseData.state == "success") {
        window.$msg("保存成功");
        this.listCoursePreparation(); // 获取用户分配得备授课列表
        // this.listGetTreeCourseThird()
      }
    },
    // 商户课程 点击确定的时候，将更新的数据给后台
    async confirm() {
      let thembArraypx = this.$refs.eltreeDatas.getCheckedNodes();

      this.courseListpx = []
      this.courseListTotal = []

      if (thembArraypx.length === 0) {
        let object = {
          merchantId: this.$route.query.id,
        }
        this.courseListpx.push(object);
      } else {
        thembArraypx.forEach((item) => {
          if (item.courseGroup === undefined || item.courseGroup === null) {
            let object = {};
            object.merchantId = this.$route.query.id;
            object.courseGoodsId = item.courseGoodsId;
            object.courseId = item.id;
            this.courseListpx.push(object);
            this.courseListTotal.push(object)
          }
        });
      }

      if (this.courseListTotal.length === 0) {
        let object = {
          merchantId: this.$route.query.id,
        };
        this.courseListTotal.push(object);
      }
      let responseData = await distributeCourse(
        JSON.stringify(this.courseListTotal)
      );

      if (responseData && responseData.state == "success") {
        window.$msg("保存成功");
        this.listDistributedCourse('编程');
        this.listMerchantCourse()
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
/deep/ .has-gutter {
  .el-checkbox {
    display: none;
  }
}
</style>

