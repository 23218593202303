<template>
  <el-drawer
    @open="open"
    :visible.sync="show"
    :with-header="false"
    direction="rtl"
    size="35%">
    <el-table :data="dataList"  class="studentTable">
      <el-table-column property="userName" label="姓名" width="150"></el-table-column>
      <el-table-column property="loginName" label="登录名" width="200"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-drawer>
</template>

<script>
// 根据教务获取学生列表
import {
  $getClassStudentList,
} from "@/api/statistics";
export default {
  data () {
    return {
      show:false,
      dataList:[],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 500, 1000],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
    }
  },
  props:{
    stuData:{
      type:Object,
      default:()=>{}
    },
  },
  methods: {
    open(){
      this.getClassStudentList()
    },
    async getClassStudentList () {
      const res = await  $getClassStudentList({
        // 当前行的id
        classId:this.stuData.class_id, 
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassStudentList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassStudentList()
    },
  },

}
</script>

<style lang="scss" scoped>
.studentTable {
  padding: 20px;
}
.el-table::before {
  height: 0px;
}
</style>
