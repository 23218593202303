<template>
  <div>
    <merchant-account v-if="type === 'account'" :merchantId="merchantId"  ></merchant-account>
    <merchant-student v-else-if="type === 'student'" :merchantId="merchantId"  ></merchant-student>
    <merchant-class v-else-if="type === 'class'" :merchantId="merchantId"  ></merchant-class>
    <merchant-timetable v-else-if="type === 'timetable'" :merchantId="merchantId"  ></merchant-timetable>
    <merchant-courseaccount v-else-if="type === 'courseaccount'" :merchantId="merchantId" ></merchant-courseaccount>
    <merchant-course v-else-if="type === 'course'" :merchantId="merchantId" ></merchant-course>
  </div>
</template>

<script>
import merchantAccount from './merchantAccount'
import merchantCourseaccount from './merchantCourseaccount'
import merchantCourse from './merchantCourse'
import merchantStudent from "./merchantStudent";
import merchantClass from './merchantClass'
import merchantTimetable from './merchantTimetable'
export default {
  props: {
    merchantId:{
      default: '',
      type: String
    },
    type:{
      default: '',
      type: String
    },
  },
  components: {
    merchantStudent,
    merchantAccount,
    merchantCourseaccount,
    merchantCourse,
    merchantClass,
    merchantTimetable
  },
  data () {
    return {}
  },
  methods: {
    updateView () {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
