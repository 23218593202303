<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" @open="openDialog" width="50%" top="5vh">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="true"
      ref="ruleForm"
      label-width="150"
    >
      <el-form-item label="商户名称：" prop="merchantId" style="width: 50%">
        <span> {{formInfo.title }}</span>
      </el-form-item>
      <el-form-item label="课程账号类型：" prop="courseGoodsId" style="width: 50%">
        <el-select
          clearable
          v-model="formInfo.courseGoodsId"
          placeholder="请选择课程账号类型"
          class="w100"
        >
          <el-option
            v-for="item in courseCatalogList"
            :key="item.id"
            :label="item.courseGoodsName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程账号数：" prop="amount" style="width: 50%">
        <el-input-number class="w100" v-model="formInfo.amount" controls-position="right" @change="handleChange" :min="1" :max="100000000000"></el-input-number>
      </el-form-item>
      <el-form-item label="备注：" prop="explain">
        <el-input
          style="width: 280px"
          v-model="formInfo.explain"
          placeholder="请输入备注"
          type="textarea"
          :rows="3"
        ></el-input>
      </el-form-item>
      <div style="text-align: left">
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
        <el-button size="small"  @click="dialogVisible = false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import {
  deleteCourseGoods,
} from "@/api/member/merchant";
import {
  courseCatalog,
} from '@/api/research/curriculum';

export default {
  props: {
    title: {type: String, default: ""},
  },
  data() {
    return {
      checkedNodeList:[],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      dialogVisible: false,
      courseCatalogList: [],

      formInfo: {
        merchantId: "",
        tilie:''
      },
      rules: {
        // merchantId: [
        //   {required: true, message: "请输入商户名称", trigger: "blur"},
        // ],
        amount: [
          {required: true, message: "请输入课程账号数", trigger: "blur"},
        ],
        courseGoodsId: [
          {required: true, message: "请选择课程账号类型", trigger: "blur"},
        ],
        explain: [
          {required: true, message: "请填写备注", trigger: "blur"},
        ],
      },
    };
  },
  methods: {
    handleChange(){

    },
    checkHandler(data,val){
      this.checkedNodeList = val.checkedNodes
    },
    /**
     * 获取课时类型
     */
    async courseCatalog() {
      let responseData = await courseCatalog(
        {
          pageNum: 1,
          pageSize: 1000,
          needCount: true
        }
      );
      if (responseData && responseData.state == "success") {
        this.courseCatalogList = responseData.body.list;
      }
    },
    openDialog() {
      this.formInfo = {
        merchantId:this.$route.query.id,
        title:this.$route.query.title
      }

      this.courseCatalog()
    },

    async deleteCourseGoods() {
      let responseData = await deleteCourseGoods(
        {
          ...this.formInfo
        }
      );

      if (responseData && responseData.state == "success") {
        window.$msg('扣除成功')
        this.$emit('updateView')
        this.dialogVisible = false
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.deleteCourseGoods()
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}

@import "../../style/dialog.scss";

</style>
