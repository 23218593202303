import { render, staticRenderFns } from "./merchantStudent.vue?vue&type=template&id=855bac04&scoped=true"
import script from "./merchantStudent.vue?vue&type=script&lang=js"
export * from "./merchantStudent.vue?vue&type=script&lang=js"
import style0 from "./merchantStudent.vue?vue&type=style&index=0&id=855bac04&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855bac04",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_manage_sbGx/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('855bac04')) {
      api.createRecord('855bac04', component.options)
    } else {
      api.reload('855bac04', component.options)
    }
    module.hot.accept("./merchantStudent.vue?vue&type=template&id=855bac04&scoped=true", function () {
      api.rerender('855bac04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/merchantAccount/detail-pane/merchantStudent.vue"
export default component.exports