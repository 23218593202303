var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-date-picker", {
    key: _vm.type,
    staticStyle: { "margin-right": "20px" },
    attrs: {
      type: _vm.type,
      "unlink-panels": "",
      disabled: _vm.disabled,
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": _vm.valueFormat,
      clearable: "",
      "picker-options": _vm.pickerOptions,
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }