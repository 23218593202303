<template>
  <div class="table" @scroll.passive="getScroll($event)">
    <span class="back-tit">校区详情</span>
    <div class="row-bg">
      <span v-text="title" style="margin-right: 25px"></span>
    </div>
<!--     classInfo：{{classInfo}}-->
    <el-tabs class="tb-tabs" v-model="activeName">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <!-- <keep-alive> -->
        <merchant-detail
          v-if="activeName==item.key"
          :type="activeName"
          :merchantId="id"
        ></merchant-detail>
        <!-- </keep-alive> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import merchantDetail from '@/components/merchantAccount/detail-pane'

export default {
  watch: {
    activeName (val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          id: this.id,
          tab: val,
          title:this.$route.query.title
        }
      })
    },
  },
  components: { merchantDetail },
  inject: ['reload'],
  data () {
    return {
      id: '', // 商户id
      title:'',
      activeName: 'account',
      tabMapOptions: [
        { label: '员工列表', key: 'account' },
        { label: '学生列表', key: 'student' },
        { label: '校区班级', key: 'class' },
        { label: '校区课表', key: 'timetable' },
        // { label: '课时订单', key: 'order' },
        { label: '课程账号', key: 'courseaccount' },
        { label: '授权课程', key: 'course' },
      ],
      scrollTopTable: 0
    }
  },
  created () {
    this.title = this.$route.query.title
    this.id = this.$route.query.id
  },

  methods: {
    back () {
      this.$router.go(-1)
    },

    getScroll (event) {
      // console.log(event.target.scrollTop)
      this.scrollTopTable = event.target.scrollTop
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.back-tit {
  margin-left: 0px !important;
}
.row-bg {
  padding: 15px !important;
  margin-top: 15px !important;
}
</style>
