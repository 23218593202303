<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="班级名称:">
              <el-input
                clearable
                v-model="searchForm.className"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="所属课程:">
              <el-input
                clearable
                v-model="searchForm.courseName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="状态：">
                <el-select
                  v-model="searchForm.progressStatus"
                  filterable
                  clearable
                  placeholder="请选择"
                  class="w100"
                >
                  <el-option
                    v-for="item in progressStatusList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="false"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card>
      <div slot="header" class="flex" style="align-items: center">
        <span>班级数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
        @sort-change="tableSortAll"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '上课进度'">
              <span class="text-underline" @click="progressClick(scope.row)">
                {{ Number(scope.row.finished) }}/{{ scope.row.total }}
              </span>
              <!-- <span v-if="scope.row.num === null">0</span>
              <span v-else class="text-underline" @click="numClick(scope.row)">{{scope.row.num}}</span> -->
            </template>
            <template v-else-if="item.label === '状态'">
              <span>{{scope.row.progressStatus}}</span>
            </template>
            <template v-else-if="item.label === '班级名称'">
              <span>{{scope.row.name}}</span>
            </template>
            <template v-else-if="item.label === '所属课程'">
              <span>{{scope.row.courseName}}</span>
            </template>
            <template v-else-if="item.label === '学员'">
              <span v-if="scope.row.studentNum === null">0</span>
              <span v-else class="text-underline" @click="stuClick(scope.row)">{{scope.row.studentNum}}</span>
            </template>
            <template v-else-if="item.label === '开班日期'">
              <span v-if="scope.row.open_date"> {{ scope.row.open_date.substring(0,19) }}</span>
              <span v-else>--</span>
            </template>
            <!-- <template v-else-if="item.label === '状态'">
              <span
                class="span-state"
                :style=" 'backgroundColor' +':' +getStateColor(scope.row.status)"
              ></span>
              <span  :style=" 'color' +':' + getStateColor(scope.row.status)">{{ scope.row.status }}</span>
            </template> -->
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <drawer-class-progress ref="showDrawer" :progressData="progressData" ></drawer-class-progress>
    <drawer-student ref="showStuDrawer" :stuData="stuData"></drawer-student>
  </div>
</template>
<script>
import drawerClassProgress from '../components/drawerClassProgress'
import drawerStudent from '../components/drawerStudent'
import getStateColor from "@/mixins/getStateColor";
import getColor from "@/mixins/getColor";
import {
  $getClassList,
  $getProgress,
} from "@/api/statistics";
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import datePicker from "@/components/date-picker";
// 默认展示列

const baseColSetting = [
  {
    label: "班级名称",
    sortable:true,
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "所属课程",
    prop: "courseName",
    sortable:true,
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "上课进度",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "学员",
    prop: "studentNum",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "开班日期",
    prop: "open_date",
    sortable:"custom",
    // prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "progressStatus",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getStateColor,getColor],
  components: { TextButton,SearchQuery,datePicker,drawerClassProgress,drawerStudent},
  data() {
    return {
      searchForm: {},
      advanced: false,
      dateTime: [],
      stuData:{},//学员
      progressData:{},//上课进度
      merchantInfo:{},
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      
      progressStatusList: [
        {
          value: "待开课",
          label: "待开课",
        },
        {
          value: "已开课",
          label: "已开课",
        },
        {
          value: "已结课",
          label: "已结课",
        },
      ],
      orderBy:'open_date',
      isAsc:false,//逆序
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    // 重置
    clearQuery(){
      this.searchForm={}
    },
    /**
     * 重置 查询
     */
    updateView(data) {
      this.advanced = data;
    },
    /**
     * 上课进度弹出框
     */
    // 
    progressClick(row){
      this.progressData = row
      this.$refs.showDrawer.show = true
    },
    
    /**
     * 学员时
     */ 
    stuClick(row){
      this.stuData = row
      this.$refs.showStuDrawer.show = true
    },
    /**
     * 获取学员列表
     */

    async getListHandler() {
      let responseData = await $getClassList(
        {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          needCount: this.pageInfo.needCount,
          merchantId: this.$route.query.id,
          orderBy: this.orderBy,
          isAsc: this.isAsc,
          ...this.searchForm
        }
      );

      if (responseData && responseData.state == "success") {
        const tableData = responseData.body.list;
        tableData.forEach((item, index) => {
          item.finished = "";
          item.total = "";
          this.getProgress(item.class_id, index);
        });
        this.tableData = tableData
        this.pageInfo.total = responseData.body.total;
      }
    },
    // 获取班级上课进度（原表格列表中的是没有上课）
    getProgress(id, idx) {
      $getProgress({
        classId: id,
      }).then((res) => {
        this.tableData[idx].finished = res.body.finished;
        this.tableData[idx].total = res.body.total;
      });
    },
    // 班级列表排序
    tableSortAll(column) {
      switch (column.prop) {
        case "name":
          this.orderBy = "className";
          break;
        case "open_date":
          this.orderBy = "openDate";
          break;
        case "courseName":
          this.orderBy = "courseName";
          break;
        case "lately_lesson":
          this.orderBy = "nextLessonStartTime";
          break;
      }
      switch (column.order) {
        case "ascending":
          this.isAsc = true;
          break;
        case "descending":
          this.isAsc = false;
      }
      this.getListHandler();
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      // return this.tableData.filter((item) => item.id == id)[0];
    },
    // confirmStatus(item) {
    //   const {id, state} = item;
    //   forbiddenMerchant({
    //     id,
    //     merchantStatus: state == "正常" ? "禁用" : '正常',
    //   }).then(() => {
    //     this.getListHandler();
    //   });
    // },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // openDetail(row) {
    //   // 打开商户详情
    //   this.$router.push({
    //     path: '/merchantAccount/merchantAccountDetail',
    //     query: {
    //       classID: row.class_id,
    //       courseId: row.courseId,
    //     },
    //   })
    // },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

