<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;">
    <el-form :inline="true" :model="searchForm">
<!--      <div class="row-bg">-->
<!--        <el-row :gutter="24">-->
<!--          <el-col :lg="5" :md="24">-->
<!--            <el-form-item label="商户名称：">-->
<!--              <el-input-->
<!--                clearable-->
<!--                v-model="searchForm.merchantName"-->
<!--                placeholder="请输入"-->
<!--                class="w100"-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :lg="5" :md="24">-->
<!--            <el-form-item label="状态：">-->
<!--              <el-select-->
<!--                clearable-->
<!--                v-model="searchForm.merchantStatus"-->
<!--                placeholder="状态"-->
<!--                class="w100"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in options"-->
<!--                  :key="item.key"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <span>-->
<!--            <el-button @click="resetForm">重置</el-button>-->
<!--            <el-button type="primary" @click="getListHandler">查询</el-button>-->
<!--          </span>-->
<!--        </el-row>-->
<!--      </div>-->
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: calc(100% - 80px);">
      <div slot="header" class="flex" style="align-items: center">
        <span>账户数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="openAddModal(null)">创建账号
        </el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <el-tag :type="statusOptions[scope.row.state]">
                {{ scope.row.state == 1 ? '启用' : '禁用' }}
              </el-tag>
            </template>
            <template v-else-if="item.label === '创建时间'">
              <span v-if="scope.row.createTime"> {{ scope.row.createTime.substring(0, 19) }}</span>
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '用户类型'">
              <span v-if="scope.row.identities.length !== 0">
                <el-tag style="margin-left: 5px" type="info" v-for="item in scope.row.identities" :key="item.name">
                    <span  v-text="item.name === '校区管理员' ?'校区管理员': item.name === '教务' ? '教务':'教师' "></span>
                </el-tag>
              </span>
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                type="text"
                @click="openAddModal(scope.row)"
              >编辑
              </el-button>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>

    <add-acount
      ref="add"
      :show="showAddCountDialog"
      :merchantInfo="merchantInfo"
      type="primary"
      @close="showAddCountDialog = false"
      @submit="addMember"
      @editsubmit="editMember"
    ></add-acount>
  </div>
</template>
<script>
import getAccountColor from "@/mixins/getStateColor";
import {
  getStudentList,
  getEmployeeList,
  addMerchantEmployee,
  editEmployee,
  forbiddenMerchant
} from "@/api/member/merchant";
import AddAcount from "./AddAcount";
import DetailModal from "@/views/member/DetailModal";
import TextButton from "@/components/TextButton";
import { passwordCheck} from '@/api/user'
// 默认展示列
import {
  getIdentityList,
} from "@/api/system/userType";
const baseColSetting = [
  {
    label: "登录账号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "用户类型",
    prop: "identities",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getAccountColor],
  components: {AddAcount, DetailModal, TextButton},
  data() {
    return {
      showAddCountDialog:false,
      merchantInfo:{},
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: {
        loginName: null,
        name: null,
        telNumber: null,
        merchantStatus: null,
        merchantName: null,
      },

      statusOptions: {
        1: "success",
        3: "danger",
      },
      options: [
        {
          value: "正常",
          label: "正常",
        },
        {
          value: "禁用",
          label: "禁用",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      identityData:[],
      // name:''
    };
  },
  created() {
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name,  pwd, loginName, state, roleNameList ,identityIdList} = value;
      const response = await addMerchantEmployee({ name, pwd:pwd, loginName, state ,roleNameList,identityIdList ,merchantId:this.$route.query.id});
      if (response.state == "success") {
        this.$message.success("添加成功");
        this.showAddCountDialog = false;
        this.pageInfo.pageNum = 1
        this.getListHandler();
      } else {
        this.$message.warning("添加失败");
      }
    },
    async editMember(value) {
      const { id, name, pwd, state ,roleNameList,loginName,identityIdList} = value;
      const response = await editEmployee({
        id,
        name,
        password:pwd,
        roleNameList,
        state,
        loginName,
        identityIdList
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        this.showAddCountDialog = false;
        this.pageInfo.pageNum = 1
        this.getListHandler();
      } else {
        this.$message.warning("编辑失败");
      }
    },
    /**
     * 获取列表
     */

    async getListHandler() {
      let responseData = await getEmployeeList(
        {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          needCount: this.pageInfo.needCount,
          merchantId: this.$route.query.id
          // ...this.searchForm,
        }
      );

      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
        this.tableData.forEach(item => {
          // item.identityNameList = []
          // item.identities.forEach(v=>{
          //   this.name = v.name
          // })
        })

      }
    },

    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const {id, state} = item;
      forbiddenMerchant({
        id,
        merchantStatus: state == "正常" ? "禁用" : '正常',
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        loginName: null,
        name: null,
        telNumber: null,
        merchantStatus: null,
        merchantName: null,
      };
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    openDetail(row) {
      // 打开商户详情
      this.$router.push({
        path: '/merchantAccount/merchantAccountDetail',
        query: {
          classID: row.class_id,
          courseId: row.courseId,
        },
      })
    },
    /**
     * 打开添加弹窗
     */
    openAddModal(row) {
      this.merchantInfo = row
      this.showAddCountDialog = true
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

