<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;">
    <!-- 表格 -->
    <el-button style="margin-bottom: 15px" type="primary" size="mini" @click="openAddModal">购买课程账号</el-button>
    <el-button style="margin-bottom: 15px" type="primary" size="mini" @click="manualDeduction">手动扣除账号</el-button>
    <classHourStatistics ref='classHour'></classHourStatistics>
    <coursePurchase
      ref="add"
      title="购买课时"
      @updateView='updateViews'
      type="primary"
    ></coursePurchase>
    <courseDelete
      ref="delete"
      title="手动扣除课时"
      @updateView='updateViews'
      type="primary"
    ></courseDelete>
  </div>
</template>
<script>
import {
  getMerchantList,
  forbiddenMerchant,
  listMerchantCourseGoods
} from "@/api/member/merchant";
import {
  courseCatalog
} from '@/api/research/curriculum';
import addPurchase from "@/components/merchantInfo/addPurchase";
import coursePurchase from "@/components/merchantInfo/coursePurchase";
import courseDelete from "@/components/merchantInfo/courseDelete";
import TextButton from "@/components/TextButton";
import classHourStatistics from '../components/classHourStatistics'
import SearchQuery from "@/components/SearchQuery";
import datePicker from "@/components/date-picker";
// 默认展示列

const baseColSetting = [
  {
    // label: "课时类型",
    label: '购买时间',
    // prop: "courseGoodsName",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "200",
  },
  {
    // label: "创建时间",
    label: "商品（课程账号类型）",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "数量",
    prop: "amount",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作员",
    prop: "creatorName",
    state: true,
    align: "left",
    width: "50px",
  },
];
export default {
  components: {addPurchase, TextButton, datePicker, classHourStatistics, SearchQuery, coursePurchase, courseDelete},
  data() {
    return {
      type: 'daterange',
      valueFormat: "yyyy-MM-dd",
      tableKey: 1,
      item: {},
      searchForm: {},
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      title: "",
      size: "small",
      tableData: [],
      courseCatalogList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    // this.getListHandler();
    this.listMerchantCourseGoods()
    this.courseCatalog()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    updateViews() {
      this.listMerchantCourseGoods();
      this.$refs.classHour.getList()
    },
    /**
     * 获取课程账号类型
     */
    async courseCatalog() {
      let responseData = await courseCatalog(
        {
          pageNum: 1,
          pageSize: 1000,
          needCount: true
        }
      );
      if (responseData && responseData.state == "success") {
        this.courseCatalogList = responseData.body.list;
      }
    },
    async getListHandler() {
      // let responseData = await getMerchantList(
      //   {
      //     pageNum: this.pageInfo.pageNum,
      //     pageSize: this.pageInfo.pageSize,
      //     needCount: this.pageInfo.needCount,
      //     ...this.searchForm,
      //   }
      // );
      // console.log(responseData)
      // if (responseData && responseData.state == "success") {
      //   this.tableData = responseData.body.list;
      //   this.pageInfo.total = responseData.body.total;
      // }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    /**
     * 订单列表
     */
    async listMerchantCourseGoods() {
      const res = await listMerchantCourseGoods({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: true,
        merchantId: this.$route.query.id,
        st: this.minStartTime,
        et: this.maxStartTime,
        courseGoodsId: this.searchForm.courseGoodsId,
        creatorName: this.searchForm.creatorName
      })
      if (res.state === 'success') {
        this.tableData = res.body.list;
        this.pageInfo.total = res.body.total;
      }
    },
    confirmStatus(item) {
      const {id, state} = item;
      forbiddenMerchant({
        id,
        merchantStatus: state == "正常" ? "禁用" : '正常',
      }).then(() => {
        this.getListHandler();
      });
    },

    /**
     * 重置 查询
     */
    updateView(data) {
      this.advanced = data;
    },
    /**
     * 重置表单
     */
    clearQuery() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {};
      this.$refs.dataPicker.time = [];
      this.maxStartTime = "";
      this.minStartTime = "";
    },
    /**
     * 查看
     */
    handleClick(item) {
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.listMerchantCourseGoods()
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.listMerchantCourseGoods();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
    },
    /**
     * 打开添加弹窗
     */
    manualDeduction() {
      this.$refs.delete.dialogVisible = true;
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.listMerchantCourseGoods()
    },
    // '选择时间'
    datetTimeGet(data) {
      if (data === null) {
        this.minStartTime = "";
        this.maxStartTime = "";
      } else {
        this.minStartTime = data[0];
        this.maxStartTime = data[1];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}

.row-backg {
  background: #fff;
  margin-bottom: 15px;
  padding: 10px 0 0px 15px;
  margin: 15px 0 0 0;
}
</style>

