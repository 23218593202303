<template>
<el-drawer
  title="课程账号明细"
  :size="'1000px'"
  :visible.sync="drawer"
  :direction="direction"
  @open='open'
  >
  <div style="margin-left: 20px">

    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>课程账号记录</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          style="max-height: calc(100vh - 270px); overflow-y: auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '数量(课时)'">
                <span
                  :style="{ color: scope.row.flag == '消费' ? 'red' : 'green' }"
                  >{{ scope.row.flag == "消费" ? "-" : "+" }} </span
                ><span>{{ scope.row.amount }}</span>
              </template>
              <template v-else-if="item.label === '说明'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.explain"
                  placement="top"
                >
                  <span class="text-oneline">{{ scope.row.explain }}</span>
                </el-tooltip>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
  </el-drawer>
</template>
<script>
import TextButton from "@/components/TextButton";
import {
  $MerchantCourseDetails,
} from "@/api/statistics";
import {
  getStudentList,
  addStudent,
  editStudent,
  setEnable,
} from "@/api/member/student";

// 默认展示列

const baseColSetting = [
  {
    label: "分类",
    prop: "flag",
    state: true,
    align: "left",
    width: "80",
  },
  {
    label: "课程账号类型",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "数量",
    prop: "amount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "备注",
    prop: "explain",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "时间",
    prop: "createTime",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作人",
    prop: "creatorName",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  props: {
    courseGoodsId: {
      type: String,
      default: () => "",
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  components: { TextButton },
  watch: {
    select: {
      handler(val) {
        this.options = [{ value: null, label: "全部" }, ...val];
      },
    },
  },
  data() {
    return {
      currentId:'',
      tableKey: 1,
      item: {},
      title: "",
      tableData: [],
      size: "default",
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      statusOptions: {
        1: "启用",
        // "2":"删除",
        3: "禁用",
      },
      options: [],

      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      drawer: false,
      direction: 'rtl',
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    open(){
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    async getListHandler() {

      let responseData = await $MerchantCourseDetails({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        courseGoodsId: this.courseGoodsId,
        merchantId:this.$route.query.id
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },

    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler(this.currentId);
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },

  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/container.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
