var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "account"
        ? _c("merchant-account", { attrs: { merchantId: _vm.merchantId } })
        : _vm.type === "student"
        ? _c("merchant-student", { attrs: { merchantId: _vm.merchantId } })
        : _vm.type === "class"
        ? _c("merchant-class", { attrs: { merchantId: _vm.merchantId } })
        : _vm.type === "timetable"
        ? _c("merchant-timetable", { attrs: { merchantId: _vm.merchantId } })
        : _vm.type === "courseaccount"
        ? _c("merchant-courseaccount", {
            attrs: { merchantId: _vm.merchantId },
          })
        : _vm.type === "course"
        ? _c("merchant-course", { attrs: { merchantId: _vm.merchantId } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }