<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>课程账号统计</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!-- 总课时=已分配+待分配+冻结课时 -->
              <!-- <template v-if="item.label === '待分配'">
                <span>{{Number(scope.row.buyTotalAmount - scope.row.costAmount - scope.row.frozenAmount)}}</span>
              </template> -->

              <template v-if="item.label === '操作'">
                <el-button
                  type="text"
                  @click="gotoDetail(scope.row)"
                  >明细</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
    </el-card>
    <!-- 明细 -->
    <drawerDetail ref="table" :select='select' :courseGoodsId ='courseGoodsId'></drawerDetail>
  </div>
</template>
<script>

import TextButton from "@/components/TextButton";
import drawerDetail from './drawerDetail.vue';
import {
  $listMerchantCourseCount
} from "@/api/statistics";
// 默认展示列

const baseColSetting = [
  {
    label: "课程账号类型",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    // sortable: true,
    width: "100",
  },
  {
    label: "总数",
    prop: "buyTotalAmount",
    state: true,
    align: "center",
    // sortable: true,
    width: "100",
  },
  {
    label: "已使用",
    prop: "costAmount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "余额",
    prop: "remainAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { TextButton , drawerDetail },
  data() {
    return {
      select:[],
      size: "default",
      tableKey: 1,
      dataList:[],
      courseGoodsId:'',
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    gotoDetail (row) {
      this.$refs.table.drawer = true;
      this.courseGoodsId = row.courseGoodsId;
    },
    getList() {
      $listMerchantCourseCount(
        {merchantId: this.$route.query.id,}
      ).then((res) => {
        if (res.state == "success") {
          this.dataList = res.body.list;
        }
      });
    },
    /**
     * 刷新
     */
    search() {
      this.getList();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-title {
  line-height: 20px;
  font-size: 12px;
}
.slider-bar-box {
  text-align: left;
}
.slider-bar {
  line-height: 20px;
  display: inline-block;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #f5c319;
}

.el-slider__bar {
  background-color: #f5c319 !important;
}
</style>
