import $axios from '@/utils/request'

// 获取用户身份列表
export function getIdentityList(data) {
    const url = '/identity/getList'
    return $axios.fPost(url, data)
}
// 用户类型关联角色列表
export function getRoleListByIdentity(data) {
    const url = '/identity/getRoleListByIdentity'
    return $axios.fGet(url, data)
}
// 修改用户类型关联得角色
export function updateIdentityRole(data) {
    const url = '/identity/updateIdentityRole'
    return $axios.fPost(url, data)
}
// 全部角色
export function getRoleListAll(data) {
    const url = '/role/roles/all'
    return $axios.fGet(url, data)
}