export default {
  methods: {
    getPreparColor(status) {
      switch (status) {
        case 'Y':
          return '#4DA54D'
        case 'N':
          return '#CE433F'
        default:
          return '#C8C6C6'
      }
    },
    getAccountColor(status) {
      switch (status) {
        case '正常':
          return '#4DA54D'
        case '禁用':
          return '#CE433F'
        default:
          return '#C8C6C6'
      }
    },
    getWorksColor(status) {
      switch (status) {
        case '正常':
          return '#4DA54D'
        case '已禁用':
          return '#CE433F'
        default:
          return '#C8C6C6'
      }
    },
    getPreparColorText(status) {
      switch (status) {
        case 'Y':
          return '可备课'
        case 'N':
          return '不可备课'
        default:
          return ''
      }
    },
    getStateColor(status) {
      switch (status) {
        case '待审核':
          return '#EB9316'
        case '审核中':
          return '#EB9316'
        case '已启用':
          return '#4DA54D'
        case '已通过':
          return '#4DA54D'
        case '未通过':
          return '#CE433F'
        case '已禁用':
          return '#f56c6c'
        case '已删除':
          return '#f56c6c'
        default:
          return '#C8C6C6'
      }
    },
    getStatusColor(status) {
      switch (status) {
        case '正在上课':
          return '#1890ff'
        case '已上课':
          return '#52c41a'
        case '未上课':
          return '#bfbfbf'
        default:
          return '#C8C6C6'
      }
    },
    getLessonFinishState(status) {
      switch (status) {
        case '已填写' :
          return '#4DA54D'
        case '未填写' :
          return '#f56c6c'
        case '已上课' :
          return '#4DA54D'
        case '未上课' :
          return '#f56c6c'
        default :
          return '#C8C6C6'
      }
    },
    getActivityColor (status) {
      switch (status) {
        case '已发布' :
          return '#4DA54D'
        case '未发布' :
          return '#909399'
        case '已删除' :
          return '#f56c6c'
        default :
          return '#C8C6C6'
      }
    },
    getStateNumColor (state) {
      switch (state) {
        case '1' :
          return '#4DA54D'
        case '2' :
          return '#909399'
        case '3' :
          return '#f56c6c'
        default :
          return '#C8C6C6'
      }
    },
    getStateTaskColor (state) {
      switch (state) {
        case 0 :
          return '#4DA54D'
        case 1 :
          return '#909399'
        case 2 :
          return '#f56c6c'
        case 3 :
          return '#f56c6c'
        case 4 :
          return '#f56c6c'
        default :
          return '#C8C6C6'
      }
    },
    getStateLessonColor (state) {
      switch (state) {
        case '已发布' :
          return '#70d445'
        case '已关闭' :
          return '#e83d2c'
        case '已停用' :
          return '#e83d2c'
        case '未发布' :
          return '#909399'
        default :
          return '#666'
      }
    },
    pythonColor (state) {
      switch (state) {
        case true :
          return '#4DA54D'
        case false :
          return '#f56c6c'
        case null :
          return '#909399'
        default :
          return '#666'
      }
    },
    getSubTypeColor (state) {
      switch (state) {
        case 4:
          return '#4DA54D'
        case 12:
          return '#4DA54D'
        case 13:
          return '#4DA54D'
        case 5:
          return '#CE433F'
        case 6:
          return '#CE433F'
        case 10:
          return '#CE433F'
        case 11:
          return '#CE433F'
        default:
          return '#EB9316'
      }
    },
    getNewsColor (state) {
      switch (state) {
        case 1:
          return '#909399'
        case 2:
          return '#70d445'
        default:
          return '#666'
      }
    },
    getGroupColor (state) {
      switch (state) {
        case '1':
          return '#4DA54D'
        case '2':
          return '#f56c6c'
        default:
          return '#666'
      }
    }
  }
}
