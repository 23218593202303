<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="所属班级:">
              <el-input
                clearable
                v-model="searchForm.className"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课节类型:">
              <!-- <el-input
                clearable
                v-model="searchForm.lessonType"
                placeholder="请输入"
                class="w100"
              ></el-input> -->
              <el-select
                  v-model="searchForm.lessonType"
                  filterable
                  clearable
                  placeholder="请选择"
                  class="w100"
                >
                  <el-option
                    v-for="item in lessonTypeList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="上课状态:">
                <el-select
                  v-model="searchForm.finishedStatus"
                  filterable
                  clearable
                  placeholder="请选择"
                  class="w100"
                >
                  <el-option
                    v-for="item in progressStatusList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="8" :md="24">
              <el-form-item label="查询日期：">
                <date-picker
                  :type='type'
                  :valueFormat='valueFormat'
                  ref="dataPicker"
                  :dateTime="searchForm.dateTime"
                  class="w100"
                  @updateView="datetTimeGet"
                ></date-picker>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="true"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card>
      <div slot="header" class="flex" style="align-items: center">
        <span>课表数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '类型'">
              <el-tag  :style=" 'background' +':' + getBackgroundColor(scope.row && scope.row.type?scope.row.type : '')">
                <span style="color:#FFF">{{ scope.row && scope.row.type == '常规' ? '常规' :scope.row && scope.row.type == '试听' ? '试听' : '补课'}}</span>
              </el-tag>
            </template>
            <template v-if="item.label === '所属班级'">
              <span>{{ scope.row && scope.row.classInfo && scope.row.classInfo.name ? scope.row.classInfo.name : ''}}</span>
            </template>
            <template v-if="item.label === '课次'">
              <span>第{{ scope.row && scope.row.courseUnits && scope.row.courseUnits[0].sort ? scope.row.courseUnits[0].sort : ''}}课</span>
            </template>
            <!-- 	 -->
            <template v-else-if="item.label === '授课老师'">
              <span v-if=" scope.row.teacherInfoSet.length !== 0">{{ scope.row.teacherInfoSet[0].userName  ? scope.row.teacherInfoSet[0].userName : ''}}</span>
            </template>
            <template v-else-if="item.label === '课节标题'">
              <span>{{ scope.row && scope.row.courseUnits && scope.row.courseUnits[0].title ? scope.row.courseUnits[0].title : ''}}</span>
            </template>
            <template v-else-if="item.label === '学员数'">
                <el-popover
                  v-if="lessonStudentList.length !== null"
                  placement="right"
                  width="350"
                  @after-enter="getStudentListByLesson(scope.row)"
                  style="height: 400px;overflow-y: scroll"
                  trigger="click">
                  <el-table :data="lessonStudentList">
                    <el-table-column property="name" label="姓名" align="center"></el-table-column>
                    <el-table-column width="160"  property="loginName" label="登录名" align="center"></el-table-column>
                    <el-table-column label="出勤状态" align="center">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isAttend === '是'" type="success">已出勤</el-tag>
                        <el-tag v-else type="danger">未出勤</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text" style="text-decoration:underline">{{scope.row.classInfo.studentNum}}</el-button>
                </el-popover>
                <span v-else>0</span>
            </template>
            <template v-else-if="item.label === '上课时间'">
              <span v-if="scope.row.start_time">
              <!-- <el-tag class="week"> {{scope.row.start_time.substring(0, 10) | weekDay}} </el-tag> -->
              <el-tag > {{scope.row.start_time.substring(0, 10) | weekDay}} </el-tag>
              {{ scope.row.start_time | formatDateStart }} {{scope.row.end_time | formatDateEnd }}</span>
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '状态'">
              <span
                class="span-state"
                :style=" 'backgroundColor' +':' +getStatusColor(scope.row.finished_status )"
              ></span>
              <span  :style=" 'color' +':' + getStatusColor( scope.row.finished_status)">{{ scope.row.finished_status }}</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-popconfirm
                @onConfirm="confirmStatus(scope.row)"
                @confirm="confirmStatus(scope.row)"
                :title="`确定撤销此次上课吗？`">
                <template >
                  <el-button slot="reference" type="text" :disabled="scope.row.finished_status === '未上课'">撤销上课</el-button>
                </template>
              </el-popconfirm>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import drawerStudent from './drawerStudent'
import getStatusColor from "@/mixins/getStateColor";
import getColor from "@/mixins/getColor";
import getBackgroundColor from "@/mixins/getBackgroundColor";

import {
  $getStudentListByLesson,
  $getLessonList,
  revertLesson,
} from "@/api/statistics";
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import datePicker from "@/components/date-picker";
import { formatDateYMD } from '@/utils/formatDate'
// 默认展示列

const baseColSetting = [
  {
    label: "所属班级",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "上课时间",
    prop: "start_time",
    state: true,
    align: "center",
    width: "300",
  },
  {
    label: "授课老师",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "课次",
    prop: "num",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "课节标题",
    prop: "createTime",
    // prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "类型",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "学员数",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getStatusColor,getColor,getBackgroundColor],
  components: { TextButton,SearchQuery,datePicker,drawerStudent},
  data() {
    return {
      lessonStudentList:[],
      type:'daterange',
      valueFormat:"yyyy-MM-dd",
      advanced: false,
      stuData:{},//学员
      progressData:{},//上课进度
      minStartTime: '',
      maxStartTime: '',
      merchantInfo:{},
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: {
      },
      statusOptions: {
        1: "success",
        3: "danger",
      },
      lessonTypeList:[
        {
          value: "常规",
          label: "常规",
        },
        {
          value: "试听",
          label: "试听",
        },
        {
          value: "补课",
          label: "补课",
        },
      ],
      progressStatusList: [
        {
          value: "已上课",
          label: "已上课",
        },
        {
          value: "未上课",
          label: "未上课",
        },
        {
          value: "正在上课",
          label: "正在上课",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    const end = new Date();
    const start = new Date();
    const weekday = start.getDay() === 0 ? start.getDay() + 7 : start.getDay();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
    end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
    this.searchForm.dateTime = [formatDateYMD(start),formatDateYMD(end)]
    this.minStartTime = formatDateYMD(start)
    this.maxStartTime = formatDateYMD(end)
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    //撤销上课
    async confirmStatus(row) {
      console.log(JSON.stringify(row))
      let responseData = await revertLesson(
        {
          lessonId: row.id,
          merchantId:this.$route.query.id,
        }
      );
      if (responseData && responseData.state == "success") {
        window.$msg('撤销上课成功')
        this.getListHandler()
      }else {
        console.log(JSON.stringify(responseData))
      }
    },
    async getStudentListByLesson (row) {
      this.lessonStudentList  = []
      const res = await  $getStudentListByLesson({
        pageNum: 1,
        pageSize: 100000,
        needCount: true,
        lessonId:row.id,
        classId:row.classInfo.class_id? row.classInfo.class_id : '',
        courseUnitId:row.courseUnits[0].id? row.courseUnits[0].id : '',
      })
      this.lessonStudentList = res.body.list
    },

    /**
     * 获取学员列表
     */

    async getListHandler() {
      let responseData = await $getLessonList(
        {
          className:this.searchForm.className,
          lessonType:this.searchForm.lessonType,
          finishedStatus:this.searchForm.finishedStatus,
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          needCount: this.pageInfo.needCount,
          merchantId: this.$route.query.id,
          minTime: this.minStartTime ? this.minStartTime + " 00:00:00" : "",
          maxTime: this.maxStartTime ? this.maxStartTime + " 23:59:59" : "",
        }
      );
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },

    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    openDetail(row) {
      // 打开商户详情
      this.$router.push({
        path: '/merchantAccount/merchantAccountDetail',
        query: {
          classID: row.class_id,
          courseId: row.courseId,
        },
      })
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 重置 查询
     */
    updateView(data) {
      this.advanced = data;
    },
    /**
     * 重置表单
     */
    clearQuery() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {};
      this.$refs.dataPicker.time = [];
      this.maxStartTime = "";
      this.minStartTime = "";
    },
    // '选择时间'
    datetTimeGet(data) {
      if (data === null) {
        this.minStartTime = "";
        this.maxStartTime = "";
      } else {
        this.minStartTime = data[0];
        this.maxStartTime = data[1];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
.week {
  display: inline-block;
  margin-right: 8px;
  color: rgba(144, 46, 209,1);
  padding: 0px 5px;
  border-width: 1px;
  border-style: solid;
  background-color: rgb(210, 173, 235);
  border-color: #7540a7;
  border-radius: 5px;
}
</style>

