<template>
  <el-date-picker
    style="margin-right: 20px;"
    v-model="time"
    :type="type"
    :key='type'
    unlink-panels
    :disabled="disabled"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :value-format="valueFormat"
    @change="handleChange"
    clearable
    :picker-options="pickerOptions">
  </el-date-picker>
</template>


<script>
/**
* @author libin
* @description 带快捷选项的日历下拉选择框 使用：<date-picker :dateTime="formData.dateTime" @updateView="updateView"></date-picker>
* */

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    type:{
      default:'datetimerange',
      type: String
    },
    valueFormat:{
      default: 'yyyy-MM-dd HH:mm:ss',
      type: String
    }
  },
  watch:{
    type:{
      handler(nV,oV){
        console.log(nV,'nVnV',oV);
      }
    }
  },
  data() {
    return {
      time: this.$attrs.dateTime || [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() + 7 : start.getDay()// getDay() 方法可返回一周（0~6）的某一天的数字。
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() + 14 : start.getDay() + 7
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '下周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() : start.getDay() - 7
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              var year = start.getFullYear();
              var month = start.getMonth()+1;
              var d = new Date(year, month, 0);
              start.setDate(1);
              end.setDate(d.getDate());
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const start = new Date();
              const end = new Date(start);
              end.setMonth(start.getMonth());
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              start.setDate(1);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('updateView', val)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
