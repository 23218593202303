var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 40px)", "overflow-y": "auto" } },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.openAddModal },
        },
        [_vm._v("购买课程账号")]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.manualDeduction },
        },
        [_vm._v("手动扣除账号")]
      ),
      _c("classHourStatistics", { ref: "classHour" }),
      _c("coursePurchase", {
        ref: "add",
        attrs: { title: "购买课时", type: "primary" },
        on: { updateView: _vm.updateViews },
      }),
      _c("courseDelete", {
        ref: "delete",
        attrs: { title: "手动扣除课时", type: "primary" },
        on: { updateView: _vm.updateViews },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }