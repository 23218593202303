export default {
  methods:{
    getBackgroundColor(state) {
      switch (state) {
        case '常规':
          return '#1890ff'
        case '试听':
          return '#13c2c2'
        case '补课':
          return '#fa541c'
        default:
          return '#C8C6C6'
      }
      
    },
  }
}