<template>
  <el-drawer
    @open="open"
    :visible.sync="show"
    :with-header="false"
    direction="rtl"
    size="35%">
    <el-table :data="dataList"  class="studentTable" style="height:calc(100vh - 80px);overflow-y:auto">
      <el-table-column property="zjName" label="课节名称" width="150">
        <template  slot-scope="scope">
          <span v-if="scope.row.courseUnits[0].title"> {{ scope.row.courseUnits[0].title }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column property="start_time" label="上课时间" width="200">
        <template  slot-scope="scope">
          <span v-if="scope.row.start_time"> {{ scope.row.start_time.substring(0,19) }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column property="finished_status" label="状态"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-drawer>
</template>

<script>
// 根据教务获取学生列表
import {
  $getClassLessonList,
} from "@/api/statistics";
export default {
  data () {
    return {
      show:false,
      dataList:[],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 500, 1000],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
    }
  },
  props:{
    progressData:{
      type:Object,
      default:()=>{}
    },
  },
  methods: {
    open(){
      this.getClassLessonList()
    },
    async getClassLessonList () {
      const res = await  $getClassLessonList({
        // et:this.maxTime,
        // st:this.minTime,
        CourseId:this.progressData.courseId,
        classId:this.progressData.class_id, //当前行的id
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassLessonList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassLessonList()
    },
  },

}
</script>

<style lang="scss" scoped>
.studentTable {
  padding: 20px;
}
.el-table::before {
  height: 0px;
}
</style>
